import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, NgZone, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';

import { UserApiActions } from '@core/store/actions/user.actions';
import { selectReceivedPushNotification, selectStructureUpdateAvailable, selectUpdateAvailable, selectUser } from '@core/store/selector/session.selectors';
import { User, UserRole } from 'app/models/user.model';
import { distinctUntilChanged, filter, firstValueFrom, map, Observable, Subject, switchMap, takeUntil, timer } from 'rxjs';
import { NavController, PopoverController, ToastController } from '@ionic/angular';
import { notifAction } from '@core/store/actions/notifications.action';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../../../environments/environment';
import { TooltipPosition } from 'app/modules/shared/components/tooltip/tooltip.model';
import { DiscussionActions } from '@core/store/actions/discussion.actions';
import { selectMessagesNonLus } from '@core/store/selector/discussion.selectors';
import buildTimestamp from '../../../../../environments/backoffice-build-date.json';
import { StorageService } from '@core/services/storage/storage.service';
import { AppInitializerService } from '@core/services/app-initializer/app-initializer.service';
import { RdvDomicileAction } from '@core/store/actions/rdv-domicile.action';
import { adminActions } from '@core/store/actions/admin.action';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePage {
  user$?: Observable<User | undefined>;
  notif?: Observable<any>;
  protected readonly environment = environment;
  @ViewChild('popover') popover: any;
  public isOpen = false;
  private readonly destroy$: Subject<void>;
  readonly position = TooltipPosition;
  nbMessagesNonLus = 0;

  constructor(
    private readonly responsive: BreakpointObserver,
    private readonly store: Store,
    private readonly titleService: Title,
    private readonly popoverController: PopoverController,
    private readonly toastController: ToastController,
    private readonly storage: StorageService,
    private readonly zone: NgZone,
    private readonly appInitService: AppInitializerService,
    private readonly navCtrl: NavController,
  ) {
    this.destroy$ = new Subject();
  }

  ionViewWillEnter(): void {
    console.log('init home');
    this.store.dispatch(RdvDomicileAction.getespece());

    this.user$ = this.store.select(selectUser).pipe(distinctUntilChanged(), takeUntil(this.destroy$));
    this.notif = this.store.select(selectReceivedPushNotification).pipe(distinctUntilChanged(), takeUntil(this.destroy$));

    this.store
      .select(selectMessagesNonLus)
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(count => (this.nbMessagesNonLus = count));
    this.checkForUpdates();
    this.checkForStructureUpdate();

    timer(0, 60000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.store.dispatch(DiscussionActions.getunreadmessagescount()));

    this.responsive.observe(Breakpoints.Large).subscribe(() => {});
    this.notif
      .pipe(
        map(notif => notif.notifications),
        map((notifs: any[]) => notifs.length),
      )
      .subscribe(nbNotif => {
        if (nbNotif > 0) {
          this.titleService.setTitle('Discussions' + (nbNotif > 0 ? ` (${nbNotif})` : ''));
        }
      });
  }

  private checkForUpdates() {
    if (!this.environment.production) {
      return;
    }
    this.store
      .select(selectUpdateAvailable)
      .pipe(distinctUntilChanged())
      .subscribe(async updateAvailable => {
        if (updateAvailable) {
          const toast = await this.toastController.create({
            message: 'Une mise à jour est disponible, veuillez rafraîchir la page',
            keyboardClose: false,
            position: 'bottom',
            translucent: true,
            duration: 10000,
            buttons: [
              {
                text: 'Masquer',
                role: 'cancel',
                icon: 'close-outline',
                handler: () => {
                  toast.dismiss();
                },
              },
              {
                text: 'Rafraîchir',
                role: 'ok',
                icon: 'refresh',
                handler: () => {
                  this.zone.run(() => location.reload());
                },
              },
            ],
          });
          await toast.present();
        }
      });
    this.storage.setToStorage('buildTimestamp', buildTimestamp.buildTimestamp.toString());
    timer(1000, 60000)
      .pipe(
        switchMap(() => this.appInitService.getLastBuildTimestamp()),
        filter(timestampFromServer => {
          let localTimestamp = this.storage.getFromStorage('buildTimestamp');

          return !!localTimestamp && +localTimestamp !== timestampFromServer;
        }),
      )
      .subscribe(newTimestamp => {
        this.storage.setToStorage('buildTimestamp', newTimestamp.toString());
        this.store.dispatch(notifAction.updateAvailable());
      });
  }

  logout() {
    this.store.dispatch(UserApiActions.logout());
    this.popoverController.dismiss();
  }

  removePopover() {
    this.popoverController.dismiss();
  }

  presentPopover(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }

  resetNotif() {
    this.store.dispatch(notifAction.resetNotifState());
  }

  ionViewDidLeave() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected readonly UserRole = UserRole;

  private async checkForStructureUpdate() {
    this.store
      .select(selectStructureUpdateAvailable)
      .pipe(distinctUntilChanged())
      .subscribe(async updateAvailable => {
        if (updateAvailable) {
          this.storage.removeFromStorage('structures');
          this.store.dispatch(adminActions.getAllStructure());
        }
      });
    if (!this.storage.getFromStorage('structureUpdateTimeStamp')) {
      this.storage.setToStorage(
        'structureUpdateTimeStamp',
        await firstValueFrom(this.appInitService.getStructureUpdateTimestamp()).then(date => date.toString()),
      );
    }
    timer(1000, 60000)
      .pipe(
        switchMap(() => this.appInitService.getStructureUpdateTimestamp()),
        filter(timestampFromServer => {
          let localTimestamp = this.storage.getFromStorage('structureUpdateTimeStamp');

          return !localTimestamp || localTimestamp !== timestampFromServer.toString();
        }),
      )
      .subscribe(newTimestamp => {
        this.storage.setToStorage('structureUpdateTimeStamp', newTimestamp.toString());
        this.store.dispatch(notifAction.structureUpdateAvailable());
      });
  }

  openUrl(notification: any) {
    if (notification.data.url) {
      window.open(notification.data.url, '_blank');
    }
  }

  clients() {
    this.navCtrl.navigateRoot('fiche-client');
  }
}
